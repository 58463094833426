<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <div class="title">
        <p v-if="pageType === 'edit'">
          修訂電影記錄 (Film Master ID) : {{ $route.query.id }}
        </p>
        <p
          v-else-if="pageType === 'create'"
          class="d-flex align-items-center justify-content-start create-title"
        >
          <span class="">開立新電影記錄 (Film Master ID) : </span>
          <span v-loading="idLoading" element-loading-spinner="el-icon-loading">
            {{ nextFilmMasterId }}
          </span>
        </p>
        <p v-else>電影記錄</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="returnRecord()"
          >返回上一頁</b-button
        >
      </div>
      <div>
        <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="index"
            :key="item.id"
            ref="row"
          >
            <b-col cols="12">
              <b-form-group
                label="Film Name (In English)*"
                label-for="h-film-name"
                label-cols-md="2"
              >
                <b-form-input
                  v-model="form.enName"
                  id="h-film-name"
                  placeholder="Film Name (In English)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="電影名稱 (中文名稱)*"
                label-for="h-cname"
                label-cols-md="2"
              >
                <b-form-input
                  v-model="form.cnName"
                  id="h-cname"
                  placeholder="電影名稱 (中文名稱)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(1)"
                label-for="h-original-name"
                label-cols-md="2"
                class="masteRecordLabel"
              >
                <b-form-input
                  id="h-original-name"
                  v-model="form.originalName"
                  placeholder="電影原名 (以該國語言記錄)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(2)"
                label-for="h-other-name"
                label-cols-md="2"
              >
                <b-form-input
                  v-model="form.otherName"
                  id="h-other-name"
                  placeholder="電影其他譯名 "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(3)"
                label-for="h-Country"
                label-cols-md="2"
              >
                <el-select
                  filterable
                  style="width: 100%"
                  v-model="form.country"
                  placeholder="製片國家"
                >
                  <el-option
                    v-for="(item, index) in selectOptions"
                    :key="index"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="select-box"
              v-for="(selectitem, selectindex) in select"
              :key="selectitem.labelRefNum"
            >
              <b-form-group
                :label="returnLabels(selectitem.labelRefNum)"
                :label-for="`h-${selectindex}`"
                label-cols-md="2"
              >
                <b-form-input
                  :id="`h-${selectindex}`"
                  v-model="form[selectindex]"
                  style="width: 200px"
                  class="per-page-selector d-inline-block mr-1"
                />
                <b-button variant="primary" @click="getList(selectindex, 'GO')"
                  >Go!</b-button
                >
              </b-form-group>
              <div class="select-box-list d-flex align-items-center">
                <div class="col-md-2" style="min-height: 150px"></div>
                <b-form-select
                  v-model="select[selectindex].selected"
                  multiple
                  :select-size="8"
                  style="height: 174px"
                  class="select-item"
                >
                  <option
                    style="white-space: normal"
                    v-for="option in select[selectindex].select"
                    :key="option.id"
                    :value="option"
                  >
                    {{ option.name }}
                  </option>
                </b-form-select>

                <div class="ml-3 mr-3">
                  <b-row class="my-1">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="addSelect(selectindex)"
                    >
                      <feather-icon icon="ArrowRightIcon" />
                    </b-button>
                  </b-row>
                  <b-row class="my-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="removeSelect(selectindex)"
                    >
                      <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                  </b-row>
                </div>
                <b-form-select
                  v-model="select[selectindex].unselected"
                  multiple
                  :select-size="8"
                  style="height: 174px"
                  class="select-item-check select-item"
                >
                  <option
                    style="white-space: normal"
                    v-for="option in select[selectindex].final"
                    :key="option.id"
                    :value="option"
                  >
                    {{ option.name }}
                  </option>
                </b-form-select>
              </div>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="returnLabels(11)"
                label-for="h-number"
                label-cols-md="2"
              >
                <b-form-input
                  id="h-number"
                  type="number"
                  v-model="form.year"
                  :placeholder="`${$moment().format('YYYY')}`"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(12)"
                label-for="h-status"
                label-cols-md="2"
              >
                <b-form-select
                  id="h-status"
                  v-model="form.status"
                  :options="statusOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(14)"
                label-for="h-poster"
                label-cols-md="2"
              >
                <UploadImgCom
                  :pageType="pageType"
                  :bannerPath.sync="form.poster"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(16)"
                label-for="h-description"
                label-cols-md="2"
              >
                <b-form-input
                  v-model="form.trailer"
                  id="h-trailer"
                  placeholder="Trailer"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(15)"
                label-for="h-description"
                label-cols-md="2"
              >
                <b-form-textarea
                  id="h-description"
                  size="sm"
                  v-model="form.description"
                  placeholder="Description"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="returnLabels(13)"
                label-for="h-remark"
                label-cols-md="2"
              >
                <b-form-textarea
                  id="h-remark"
                  size="sm"
                  v-model="form.remark"
                  placeholder="Remark"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
          </b-row>
        </b-form>
      </div>
      <b-col offset-md="5">
        <b-button @click="saveInfo" type="submit" variant="primary" class="mr-1"
          >Submit</b-button
        >
      </b-col>
      <filmVersionRecord
        v-if="pageType !== 'create'"
        :master-id="master_id"
        :version-info="filmDetail.film"
        :film-name="`${filmDetail.name_chinese}*${filmDetail.name_english}`"
      />
    </b-card>
  </section>
</template>
<script>
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import filmVersionRecord from "./filmVersionRecord.vue"
import { getDistributorApi } from "@/api/film-material-management/distributor"
import { getActorApi } from "@/api/film-material-management/actor"
import { getDirectorApi } from "@/api/film-material-management/director"
import { getGenreApi } from "@/api/film-material-management/genre"
import { getProducerApi } from "@/api/film-material-management/producer"
import { getProductionApi } from "@/api/film-material-management/productionCompany"
import { getScriptwriterApi } from "@/api/film-material-management/scriptwriter"
import { getManageProperties } from "@/api/properties"
import {
  getFilmMasterDetail,
  editFilmMaster,
  creatFilmMaster,
  nextMasterId,
} from "@/api/film-material-management/filmMaster"
import UploadImgCom from "@/views/components/uploadImg.vue"

export default {
  components: {
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BFormSelect,
    filmVersionRecord,
    UploadImgCom,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      idLoading: false,
      loading: false,
      form: {
        enName: "",
        cnName: "",
        originalName: "",
        otherName: "",
        country: "",
        distributor: "",
        actor: "",
        director: "",
        genre: "",
        scriptwriter: "",
        production: "",
        producer: "",
        year: "",
        remark: "",
        status: "",
        description: "",
        poster: "",
        trailer: "",
      },
      items: [
        {
          id: 1,
          selected: "male",
          selected1: "designer",
          prevHeight: 0,
        },
      ],
      selectOptions: [],
      statusOptions: ["", "No_Show", "Delete"],
      show: false,
      selectview: true,
      select: {
        director: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Director",
          labelRefNum: 4,
        },
        scriptwriter: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Scriptwriter",
          labelRefNum: 5,
        },
        actor: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Actor",
          labelRefNum: 6,
        },
        distributor: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Distributor",
          labelRefNum: 7,
        },
        genre: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Genre",
          labelRefNum: 8,
        },
        production: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Production company",
          labelRefNum: 9,
        },
        producer: {
          select: [],
          selected: [],
          unselected: [],
          final: [],
          title: "Producer",
          labelRefNum: 10,
        },
      },
      isMobile: false,
      pageType: "",
      master_id: "",
      filmMasterDetail: "",
      distributorVal: "",
      filmDetail: {},
      nextFilmMasterId: "",
    }
  },
  computed: {
    returnLabels() {
      return function (val) {
        if (val === 1) {
          return "電影原名 (以該國語言記錄) Original Film name in it Own Language"
        } else if (val === 2) {
          return "電影其他譯名 Other Names"
        } else if (val === 3) {
          return "製片國家 Production Country"
        } else if (val === 4) {
          return "導演 Director"
        } else if (val === 5) {
          return "編劇 Scriptwriter"
        } else if (val === 6) {
          return "演員 Actor"
        } else if (val === 7) {
          return "發行商 Distributor"
        } else if (val === 8) {
          return "片種 Genre"
        } else if (val === 9) {
          return "出品公司 Production company"
        } else if (val === 10) {
          return "監製 Producer"
        } else if (val === 11) {
          return "出產年份 Year*"
        } else if (val === 12) {
          return "狀況 Status"
        } else if (val === 13) {
          return "備注 Remark"
        } else if (val === 14) {
          return "海報 Poster"
        } else if (val === 15) {
          return "電影描述 Description"
        } else if (val === 16) {
          return "預告 Trailer"
        }
      }
    },
  },

  mounted() {
    if (this.pageType === "create") {
      this.nextMasterId()
    }
    Object.keys(this.select).forEach((key) => {
      this.getList(key)
    })
    const that = this
    that.mobileDevice()
  },
  methods: {
    nextMasterId() {
      this.idLoading = true
      nextMasterId().then((res) => {
        this.nextFilmMasterId = res.data
        this.idLoading = false
      })
    },
    //get country list
    getManageProperties() {
      getManageProperties("film_country").then((res) => {
        const list = []
        res.data.map((el) => {
          list.push({
            text: `${el.name_en}[${el.name_zh_hk}]`,
            value: el.name_en,
          })
        })
        list.unshift({ text: "", value: "" })
        this.selectOptions = list
      })
    },
    getFilmMasterDetail() {
      this.loading = true
      getFilmMasterDetail(this.master_id).then((res) => {
        this.filmDetail = res.data
        this.filmDetail.film.map((el) => {
          el.runVer = el.run_ver.run_ver
        })
        this.distributorVal = res.data.distributor
        this.form.cnName = res.data.name_chinese
        this.form.enName = res.data.name_english
        this.form.originalName = res.data.name_org
        this.form.otherName = res.data.name_others
        this.form.country = res.data.country
        this.form.year = res.data.ver_year
        this.form.remark = res.data.film_extra_info
          ? res.data.film_extra_info.remark
          : ""
        this.form.status = res.data.status
        this.filmMasterDetail = res.data.film_extra_info
        this.form.poster = res.data.film_extra_info
          ? res.data.film_extra_info.poster_path
          : ""
        this.form.description = res.data.film_extra_info
          ? res.data.film_extra_info.description
          : ""
        this.form.trailer = res.data.film_extra_info
          ? res.data.film_extra_info.trailer_url
          : ""
        this.loading = false
      })
    },
    saveInfo() {
      if (this.pageType === "edit") {
        this.editFilmMaster()
      } else {
        this.creatFilmMaster()
      }
    },
    filmData() {
      const directorList = []
      const actorList = []
      const scriptwriterList = []
      const genreList = []
      const distributorList = []
      const productionList = []
      const producerList = []
      if (this.select.director.final && this.select.director.final.length > 0) {
        this.select.director.final.map((el) => {
          directorList.push(el.id)
        })
      }
      if (
        this.select.scriptwriter.final &&
        this.select.scriptwriter.final.length > 0
      ) {
        this.select.scriptwriter.final.map((el) => {
          scriptwriterList.push(el.id)
        })
      }
      if (this.select.actor.final && this.select.actor.final.length > 0) {
        this.select.actor.final.map((el) => {
          actorList.push(el.id)
        })
      }
      if (this.select.genre.final && this.select.genre.final.length > 0) {
        this.select.genre.final.map((el) => {
          genreList.push(el.id)
        })
      }
      if (
        this.select.distributor.final &&
        this.select.distributor.final.length > 0
      ) {
        this.select.distributor.final.map((el) => {
          distributorList.push(el.id)
        })
      }
      if (
        this.select.production.final &&
        this.select.production.final.length > 0
      ) {
        this.select.production.final.map((el) => {
          productionList.push(el.id)
        })
      }
      if (this.select.producer.final && this.select.producer.final.length > 0) {
        this.select.producer.final.map((el) => {
          producerList.push(el.id)
        })
      }
      const data = {
        distributor: distributorList,
        name_org: this.form.originalName,
        name_chinese: this.form.cnName || "-",
        name_others: this.form.otherName,
        name_english: this.form.enName || "-",
        country: this.form.country,
        year: this.form.year,
        status: this.form.status,
        actor: actorList,
        director: directorList,
        scriptwriter: scriptwriterList,
        film_cat: genreList,
        production: productionList,
        producer: producerList,
        remark: this.form.remark,
        poster: this.form.poster,
        description: this.form.description,
        trailer: this.form.trailer,
      }
      return data
    },
    editFilmMaster() {
      const data = this.filmData()
      editFilmMaster(this.master_id, data).then((res) => {
        this.getFilmMasterDetail()
        this.$router.go(-1)
      })
    },
    creatFilmMaster() {
      const data = this.filmData()
      creatFilmMaster(data).then((res) => {
        this.$router.push({
          path: "/dashboard/film-record-detail",
          query: { type: "review", id: res.data.id },
        })
      })
    },
    returnRecord() {
      this.$router.go(-1)
    },
    unique(arr, selectFinal) {
      let filteredArray1 = arr.filter((item1) => {
        // 检查数组二中是否存在与数组一中当前元素相同的 id
        return !selectFinal.some((item2) => item1.id === item2.id)
      })
      return filteredArray1
    },
    getList(type, status) {
      const data = {
        page: 1,
        count: 20000,
        search: this.form[type],
      }
      switch (type) {
        case "distributor":
          getDistributorApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.distributor_name_en.localeCompare(
                  b.distributor_name_en
                )
              })
              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.distributor_name_en} (${el.distributor_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })
              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status && this.filmDetail
              ) {
                this.filmDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "director":
          getDirectorApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.director_name_en.localeCompare(b.director_name_en)
              })
              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.director_name_en} (${el.director_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })
              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail 
              ) {
                this.filmMasterDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "scriptwriter":
          getScriptwriterApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.scriptwriter_name_en.localeCompare(
                  b.scriptwriter_name_en
                )
              })

              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.scriptwriter_name_en} (${el.scriptwriter_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })

              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail
              ) {
                this.filmMasterDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "actor":
          getActorApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.actor_name_en.localeCompare(b.actor_name_en)
              })

              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.actor_name_en} (${el.actor_name_zh_hk}) (Sex:${el.sex} )`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })

              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail 
              ) {
                this.filmMasterDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    }) (Sex:${
                      this.filmMasterDetail[`${type}_detail`][index].sex
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "genre":
          getGenreApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.filmcat_name_en.localeCompare(b.filmcat_name_en)
              })

              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.filmcat_name_en} (${el.filmcat_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })
              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail 
              ) {
                const isType = "film_cat"
                this.filmMasterDetail[isType].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${isType}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${isType}_detail`][index][
                        `${isType}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${isType}_detail`][index][
                        `${isType}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "production":
          getProductionApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.production_name_en.localeCompare(b.production_name_en)
              })

              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.production_name_en} (${el.production_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })
              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail 
              ) {
                this.filmMasterDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break
        case "producer":
          getProducerApi(data)
            .then((res) => {
              const dataList = res.data.slice().sort((a, b) => {
                return a.producer_name_en.localeCompare(b.producer_name_en)
              })

              const arr = []
              dataList.forEach((el) => {
                const obj = new Object()
                obj.id = el.id
                obj.name = `${el.producer_name_en} (${el.producer_name_zh_hk})`
                if (this.select[type].final.length === 0) {
                  arr.push(obj)
                } else {
                  this.checkObject(obj, this.select[type].final)
                  if (this.selectview === true) {
                    arr.push(obj)
                  }
                }
              })
              if (
                (this.pageType === "edit" || this.pageType === "review") &&
                !status &&
                this.filmMasterDetail 
              ) {
                this.filmMasterDetail[type].map((el, index) => {
                  this.select[type].final.push({
                    id: this.filmMasterDetail[`${type}_detail`][index][`id`],
                    name: `${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_en`
                      ]
                    } (${
                      this.filmMasterDetail[`${type}_detail`][index][
                        `${type}_name_zh_hk`
                      ]
                    })`,
                  })
                })
              }
              this.select[type].select = this.unique(
                arr,
                this.select[type].final
              )
            })
            .catch((error) => {
              console.log(error)
            })
          break

        default:
          break
      }
    },
    addSelect(text) {
      this.select[text].selected.forEach((el) => {
        const obj = new Object()
        obj.id = el.id
        obj.name = el.name
        this.select[text].final.push(obj)
      })
      this.select[text].select = this.select[text].select.filter(
        (val) => !this.select[text].selected.includes(val)
      )
    },
    removeSelect(text) {
      this.select[text].unselected.forEach((el) => {
        const obj = new Object()
        obj.id = el.id
        obj.name = el.name
        this.select[text].select.push(obj)
      })
      this.select[text].final = this.select[text].final.filter(
        (val) => !this.select[text].unselected.includes(val)
      )
    },
    inputBlur() {},
    checkObject(obj, final) {
      final.forEach((element) => {
        if (JSON.stringify(element) === JSON.stringify(obj)) {
          this.selectview = false
        } else {
          this.selectview = true
        }
      })
    },
    mobileDevice() {
      window.screenWidth = document.body.clientWidth
      this.screenWidth = window.screenWidth
      if (this.screenWidth < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  created() {
    this.pageType = this.$route.query.type
    this.master_id = this.$route.query.id
    const film_name = this.$route.query.film_name
    if (film_name) {
      let film_name_cn = ""
      let name_en = ""
      let film_name_en = ""
      if (film_name.includes("*")) {
        film_name_cn = film_name.split("*")[0]
        name_en = film_name.split("*")[1]
        film_name_en = name_en.split("(").slice(0, -1).join("(")
      } else {
        film_name_cn = film_name
        film_name_en = film_name
      }

      this.form.cnName = film_name_cn
      this.form.enName = film_name_en
    }
    if (this.pageType === "edit" || this.pageType === "review") {
      this.getFilmMasterDetail()
    }
    this.getManageProperties()
  },
}
</script>
<style lang="scss" scope="scoped">
.line {
  height: 3px;
}
.lh30 {
  width: 250px;
}
.form-row {
  align-items: center;
  margin-top: 15px;
}
.select-box {
  span {
    display: block;
    width: 100px;
  }
  .select-box-list {
    .select-item {
      margin-top: 1px;
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }
      ul {
        border: #f1f1f1 1px solid;
        width: auto;
        min-width: 200px;
        min-height: 150px;
      }
      li {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
      }
      li:hover {
        background: #f1f1f1;
      }
    }
  }
}
</style>

<style lang="scss">
.masteRecordsLabels {
  // margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.create-title {
  .el-loading-spinner {
    margin-top: -9px;
  }
}
</style>
